
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

import PictureComp from '@/components/picture/PictureComp.vue';

import { ThumborOptions } from '@/filters/thumbor';
import { FormattedCompetitor } from '@/helpers/sports/config/singleStepContexts';

@Component({
	name: 'CompetitorLogo',
	components: { PictureComp },
})
export default class CompetitorLogo extends Vue {
	readonly imageProps = {
		png: {
			type: 'image/png',
			extension: '.png',
		},
	};
	readonly imagePropFilter = ['png'];

	@Prop({ required: true }) competitor: FormattedCompetitor;
	@Prop({ default: 'small' }) size: 'big' | 'small';

	get imageProfile() {
		return this.size === 'big' ? 'competitor_icon' : 'competitor_small_icon';
	}

	get hasIcon() {
		return !!this.competitor?.iconUrl;
	}

	get logo() {
		return Vue.filter('Thumbor')({
			url: this.competitor?.iconUrl ?? '',
			imageType: this.imageProfile,
			extension: '', // we already have the extension with the format param
		} as ThumborOptions);
	}

	get name() {
		return this.competitor?.name;
	}
}
